import cookie from 'js-cookie';
import { NextPageContext, NextPage } from 'next';
import Error from 'next/error';
import Router from 'next/router';
import { Component } from 'react';
import { Network } from 'state/network';
import { User } from 'state/user';
import fetch from 'isomorphic-unfetch';
const { API_URL } = process.env;
import nextCookie from 'next-cookies';
import cookieOptions from "./cookieOptions";

function login({ token, redirectUrl = '/' }: { token: string; redirectUrl?: string }) {
  const in30Days = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);
  cookie.set('token', token, cookieOptions);
  Router.push('/issuers');
}

async function logout(isServer = false) {
  const refreshToken = cookie.get("refreshToken");
  await fetch(`${API_URL}/api/users/token/clear`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      refreshToken
    })
  });

  cookie.remove('token');
  cookie.remove('refreshToken');

  // to support logging out from all windows
  if (!isServer) {
    window.localStorage.setItem('logout', Date.now().toString());
    Router.push('/login');
  }
}

export const refreshTokenAction = async (): Promise<any> => {
  const refreshToken = cookie.get("refreshToken");
  try {
    const refresherRes = await fetch(`${API_URL}/api/users/token/refresh`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        refreshToken
      })
    });
    if (refresherRes.ok) {
      const refreshResponse = await refresherRes.json();
      const { user: newUser } = refreshResponse;
      cookie.set('refreshToken', newUser?.refreshToken, cookieOptions)
      cookie.set('token', newUser?.token, cookieOptions);
      return newUser?.token;
    }
    await logout(false);
    return refresherRes;
  } catch (error) {
    await logout(false);
    return error;
  }
};

function auth(ctx: NextPageContext) {
  const { token } = nextCookie(ctx);

  /*
   * If `ctx.req` is available it means we are on the server.
   * Additionally if there's no token it means the user is not logged in.
   */
  if (ctx?.req && !token) {
    if (ctx?.res) {
      ctx?.res?.writeHead(302, { Location: '/login' });
      ctx?.res?.end();
    }
  }

  // We already checked for server. This should only happen on client.
  if (!token) {
    if (typeof window !== 'undefined') {
      Router.push('/login');
    }
  } else {
    // extend the cookie to 10 mins and expire if an user is not active
    const in30Days = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);
    cookie.set('token', token, cookieOptions);
  }

  return token;
}

export function hasPermission(permissions: string | string[], userPermissions: Array<string> | null) {
  let isAllow = true;
  if (Array.isArray(permissions)) {
    isAllow = permissions.some((permission) => userPermissions?.includes(permission));
  } else if (!userPermissions?.includes(permissions)) {
    isAllow = false;
  }
  return isAllow;
}

export const checkPermissions = (permissions: string[][], userPermission: string[]): boolean => 
   permissions.some((array) => array.every((perm) => userPermission.includes(perm)));



function withAuthSync(WrappedComponent: NextPage<any>, permissions: string | Array<string>) {
  return class extends Component {
    static async getInitialProps(ctx: NextPageContext) {
      const token = auth(ctx);
      const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
      if (!token) {
        return { ...componentProps, token };
      }
      const res = await fetch(`${API_URL}/api/users/token/verify`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          accessToken: token
        })
      });
      const errorCode = res.status > 200 ? res.status : false;
      const result = await res.json();
      const {data: user} = result;

      if (res.ok) {
        return { ...componentProps, errorCode, user, token };
      }

      if (result.type === "TOKEN_EXPIRED") {
        const { refreshToken } = nextCookie(ctx);
        const refresherRes = await fetch(`${API_URL}/api/users/token/refresh`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            refreshToken
          })
        });
        const refreshResponse = await refresherRes.json();
        // console.log("refreshResponse",refreshResponse);
        const {user: newUser} = refreshResponse;
        if (refresherRes.ok) {
          cookie.set('refreshToken', newUser?.refreshToken, cookieOptions);
          cookie.set('token', newUser?.token, cookieOptions);
          if (ctx.res) {
            ctx.res.setHeader('set-cookie', [`refreshToken=${newUser?.refreshToken}`, `token=${newUser?.token}`]);
            ctx.res.writeHead(302, {
              Location: ctx.pathname,
            });
            ctx.res.end();
          } else {
            Router.push(ctx.pathname);
          }
          return {...componentProps, errorCode, user: newUser, token: newUser?.token};
        }

        if (ctx.res) {
          ctx.res.setHeader('set-cookie', [`refreshToken=`, `token=`]);
          ctx.res.writeHead(302, {
            Location: '/login',
          });
          ctx.res.end();
        } else {
          Router.push('/login');
        }
        return { ...componentProps, errorCode, user, token };
      }

      if (ctx.res) {
        ctx.res.setHeader('set-cookie', [`refreshToken=`, `token=`]);
        ctx.res.writeHead(302, {
          Location: '/login',
        });
        ctx.res.end();
      } else {
        Router.push('/login');
      }

      return { ...componentProps, errorCode, token };
    }

    componentDidMount() {
      window.addEventListener('storage', this.syncLogout);
    }

    componentWillUnmount() {
      window.removeEventListener('storage', this.syncLogout);
      window.localStorage.removeItem('logout');
    }

    syncLogout = (event: StorageEvent) => {
      if (event.key === 'logout') {
        Router.push('/login');
      }
    };

    render() {
      const { errorCode, user, token, ...rest } = this.props as any;

      if (user && permissions && !hasPermission(permissions, user.permissions)) {
        return <Error statusCode={403} title="Access denied" />;
      }

      // Token expired
      if (!user) {
        return null;
      }

      if (errorCode) {
        return <Error statusCode={errorCode} />;
      }
      return (
        <Network.Provider initialState={token}>
          <User.Provider initialState={{ user, token }}>
            <WrappedComponent {...rest} />
          </User.Provider>
        </Network.Provider>
      );
    }
  };
}

export { login, logout, withAuthSync, auth };
