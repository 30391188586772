import useLocalStorage from 'hooks/useLocalStorage';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { createContainer } from 'unstated-next';

interface ConnectionStatus {
  status: string;
  message: string;
}

export function useNetwork(token: string) {
  // const { data: networks = [] } = useSWR(token ? '/api/network-providers' : null, (url) =>
  //   fetchApi(url, {
  //     method: 'POST',
  //     headers: {
  //       authorization: `Bearer ${token}`,
  //     },
  //   }).then((resp) => resp.json())
  // );
 const networks = [];
  const [initialState, setInitState] = useLocalStorage(
    'network',
    Array.isArray(networks) && networks.length > 0 ? networks[0] : undefined
  );
  const [network, setNetwork] = useState(initialState);
  const [contract, setContract] = useState<{
    contract: Record<string, any>;
  }>({ contract: {} });
  const [selectedAddress, setSelectedAddress] = useLocalStorage('address', process.env.CONTRACT_NAME);
  const [selectedVersion, setSelectedVersion] = useLocalStorage('version', process.env.CONTRACT_VERSION);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>({
    status: 'disconnected',
    message: 'Disconnected',
  });

  const [messages, setMessages] = useState([]);
  const [expandMessages, setExpandMessages] = useState(true);

  const onChange = (selectedNetwork: { id: number; name: string }) => {
    setNetwork(selectedNetwork);
    if (network.id) {
      toast('Reloading...');
      if (typeof window !== 'undefined') {
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    }
  };

  const isReady = network?.id && selectedAddress?.length > 0 && selectedVersion?.length > 0;

  useEffect(() => {
    if (networks?.length > 0 && JSON.stringify(network) !== JSON.stringify(networks[0])) {
      const temp = networks[0] || network;
      setInitState(temp);
    }
  }, [network, networks]);
  // Detect connection status when select network id,name and version
  useEffect(() => {
    if (contract?.contract?.contractName) {
      if (contract.contract.contractName !== selectedAddress) {
        setConnectionStatus({
          status: 'mismatch',
          message: 'Address mismatch',
        });
      } else if (contract.contract.contractVersion !== selectedVersion) {
        setConnectionStatus({
          status: 'mismatch',
          message: 'Version mismatch',
        });
      } else {
        setConnectionStatus({
          status: 'connected',
          message: 'Connected',
        });
      }
    } else if (contract?.contract?.addr) {
      setConnectionStatus({
        status: 'error',
        message: `Contract not found.`,
      });
    }
  }, [contract.contract, selectedAddress, selectedVersion]);

  // const { whitelist } = useAccountWhitelist(token, {
  //   networkId: network?.id,
  //   contractName: selectedAddress,
  //   contractVersion: selectedVersion,
  // });

  return {
    network,
    onChange,
    connectionStatus,
    setConnectionStatus,
    contract,
    setContract,
    selectedVersion,
    setSelectedVersion,
    selectedAddress,
    setSelectedAddress,
    isReady,
    whitelist: [],
    apiQuery: {
      networkId: network?.id,
      contractName: selectedAddress,
      contractVersion: selectedVersion,
    },
    networks,
    messages,
    setMessages,
    expandMessages,
    setExpandMessages,
    unit: () => {
      const unitValue = contract?.contract?.contractUnit ?? '';
      if (unitValue !== 'N/A') return unitValue;

      return '';
    },
  };
}

export const Network = createContainer(useNetwork);
