import { useState } from 'react';
import { createContainer } from 'unstated-next';

export function useUser(
  initValues: {
    user: { id: string; user_name: string; permissions: Array<string>; full_name: string };
    token: string;
  } = {
    user: {
      id: '',
      user_name: 'guest',
      full_name: '',
      permissions: []
    },
    token: '',
  }
) {
  const [user, setUser] = useState(initValues);
  const {
    user: { id, permissions, user_name: username, full_name },
    token,
  } = user;

  const [settings, setSettings] = useState<Record<string, string>>();

  // useEffect(() => {
  //   if (token) {
  //     fetchApi('/api/settings', {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((resp) => resp.json())
  //       .then((result) => {
  //         const settingsObj = {};
  //
  //         result.forEach((setting) => {
  //           settingsObj[setting.config_key] = setting.config_value;
  //         });
  //         setSettings(settingsObj);
  //       });
  //   }
  // }, [token]);

  return {
    id,
    permissions,
    username,
    full_name,
    token,
    setUser,
    settings,
  };
}

export const User = createContainer(useUser);
